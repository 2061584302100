import React from 'react';
import { css } from '@emotion/react';
import Image from '../../atoms/Image/Image';
import Text from '../../atoms/Text/Text';
import { Link } from 'gatsby';

export default function SliderItemPredeterminado({ data }) {
  const textCss = css`
    pointer-events: none;
    font-family: 'Montserrat Bold', sans-serif;
    width: calc(100vw * (calc(1250 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(100vw * (calc(100 / var(--width_base))));
    z-index: 2;
    @media (max-width: 767px) {
      width: 100%;
      padding-left: calc(100vw * (calc(16 / var(--width_base))));
      padding-right: calc(100vw * (calc(16 / var(--width_base))));
      bottom: calc(100vw * (calc(73 / var(--width_base))));
    }
  `;

  const titleCss = css`
    pointer-events: none;
    font-size: calc(100vw * (calc(39 / var(--width_base))));
    line-height: calc(100vw * (calc(41 / var(--width_base))));
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(25 / var(--width_base))));
      line-height: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;

  const imageContentCss = css`
    position: relative;
    img {
      min-height: calc(100vw * (calc(500 / var(--width_base))));
      height: 95vh;
      object-position: bottom !important;
    }
    &::before {
      content: '';
      position: absolute;
      height: calc(100vw * (calc(250 / var(--width_base))));
      left: 0;
      right: 0;
      pointer-events: none;
      bottom: 0;
      z-index: 1;
      background: -moz-linear-gradient(
        50% -15.24% -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -webkit-linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -webkit-gradient(
        linear,
        50% -15.24%,
        50% 104.78%,
        color-stop(0, rgba(255, 255, 255, 0)),
        color-stop(1, rgba(0, 0, 0, 1))
      );
      background: -o-linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      background: -ms-linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#000000' ,GradientType=0)";
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#000000' , GradientType=0);
      pointer-events: none;

      @media (max-width: 767px) {
        content: none;
      }
    }
  `;
  const sliderItemCss = css`
    position: relative;
  `;

  const cardImageCss = css`
    height: calc(100vw * (calc(40 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
  `;

  const cardLineCss = css`
    height: calc(100vw * (calc(1 / var(--width_base))));
    background-color: white;
    width: calc(100vw * (calc(120 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(50 / var(--width_base))));
    display: block;
    margin-left: auto;
    margin-right: auto;
  `;

  const cardBtn = css`
    background-color: #93ba1f;
    color: white;
    font-size: calc(100vw * (calc(14 / var(--width_base))));
    line-height: calc(100vw * (calc(16 / var(--width_base))));
    font-family: 'Montserrat Regular', sans-serif;
    padding: calc(100vw * (calc(7 / var(--width_base))))
      calc(100vw * (calc(40 / var(--width_base))))
      calc(100vw * (calc(7 / var(--width_base))))
      calc(100vw * (calc(40 / var(--width_base))));
    border-bottom-left-radius: calc(100vw * (calc(6 / var(--width_base))));
    border-top-right-radius: calc(100vw * (calc(6 / var(--width_base))));

    @media (max-width: 767px) {
      background-color: transparent;
      color: white;
      border: 1px solid #93ba1f;
      border-bottom-left-radius: initial;
      border-top-right-radius: initial;
      padding: calc(100vw * (calc(7 / var(--width_base))))
        calc(100vw * (calc(15 / var(--width_base))))
        calc(100vw * (calc(7 / var(--width_base))))
        calc(100vw * (calc(15 / var(--width_base))));
    }
  `;

  const cardTextCss = css`
    text-align: center;
    font-size: calc(100vw * (calc(23 / var(--width_base))));
    line-height: calc(100vw * (calc(27 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(18 / var(--width_base))));
    font-weight: 600;
    font-family: 'Montserrat Regular', sans-serif;

    @media (max-width: 767px) {
      margin-bottom: calc(100vw * (calc(25 / var(--width_base))));
    }
  `;

  const listCss = css`
    display: flex;
    justify-content: center;
    margin-bottom: calc(100vw * (calc(40 / var(--width_base))));
  `;

  const listItemCss = css`
    border: 1px solid #93ba1f;
    padding: calc(100vw * (calc(9 / var(--width_base))))
      calc(100vw * (calc(16 / var(--width_base))))
      calc(100vw * (calc(6 / var(--width_base))))
      calc(100vw * (calc(16 / var(--width_base))));
    font-size: calc(100vw * (calc(14 / var(--width_base))));
  `;

  const listSubCss = css`
    display: flex;
    justify-content: center;
    border: 1px solid #93ba1f;
    align-items: center;
  `;

  const listItem2Css = css`
    padding: calc(100vw * (calc(9 / var(--width_base))))
      calc(100vw * (calc(16 / var(--width_base))))
      calc(100vw * (calc(6 / var(--width_base))))
      calc(100vw * (calc(16 / var(--width_base))));
    font-size: calc(100vw * (calc(14 / var(--width_base))));
    text-align: center;
  `;

  const interlineCss = css`
    width: calc(100vw * (calc(1 / var(--width_base))));
    height: calc(100vw * (calc(20 / var(--width_base))));
    display: block;
    flex-shrink: 0;
    background-color: #93ba1f;
  `;

  const cardCss = css`
    position: absolute;
    bottom: calc(100vw * (calc(130 / var(--width_base))));
    right: calc(100vw * (calc(180 / var(--width_base))));
    min-width: calc(100vw * (calc(327 / var(--width_base))));
    background-color: #4e4e50;
    color: white;
    text-align: center;
    pointer-events: none;

    @media (max-width: 767px) {
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      max-height: calc(100vw * (calc(280 / var(--width_base))));
      // right: calc(100vw * (calc(16 / var(--width_base))));
      // bottom: calc(100vw * (calc(70 / var(--width_base))));
      width: calc(100vw * (calc(130 / var(--width_base))));
      border-top-left-radius: calc(100vw * (calc(15 / var(--width_base))));
      border-top-right-radius: calc(100vw * (calc(15 / var(--width_base))));
    }
  `;
  const cardContentCss = css`
    position: relative;
    padding: calc(100vw * (calc(50 / var(--width_base))));
    @media (max-width: 767px) {
      padding: calc(100vw * (calc(50 / var(--width_base))))
        calc(100vw * (calc(35 / var(--width_base))));
    }
  `;

  const cardContentStyle3Css = css`
    position: absolute;
    inset: 0;
  `;

  const cardImageStyle3Css = css`
    position: absolute;
    top: calc(100vw * (calc(80 / var(--width_base))));
    right: calc(100vw * (calc(60 / var(--width_base))));

    @media (max-width: 767px) {
      top: calc(100vw * (calc(40 / var(--width_base))));
      right: calc(100vw * (calc(20 / var(--width_base))));
    }

    &,
    & img {
      height: calc(100vw * (calc(100 / var(--width_base))));
      width: calc(100vw * (calc(100 / var(--width_base))));
      @media (max-width: 767px) {
        height: calc(100vw * (calc(50 / var(--width_base))));
        width: calc(100vw * (calc(50 / var(--width_base))));
      }
    }
  `;

  const cardTextStyle3Css = css`
    position: absolute;
    top: calc(100vw * (calc(80 / var(--width_base))));
    left: calc(100vw * (calc(60 / var(--width_base))));
    color: white;
    font-size: calc(100vw * (calc(38 / var(--width_base))));
    font-family: 'Montserrat Regular', sans-serif;
    // font-family: "Montserrat Medium", sans-serif;

    @media (max-width: 767px) {
      top: calc(100vw * (calc(40 / var(--width_base))));
      left: calc(100vw * (calc(20 / var(--width_base))));
      font-size: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  const cardText2Style3Css = css`
    position: absolute;
    top: 50%;
    left: calc(100vw * (calc(200 / var(--width_base))));
    right: calc(100vw * (calc(200 / var(--width_base))));
    color: white;
    text-align: center;
    font-size: calc(100vw * (calc(55 / var(--width_base))));
    text-transform: uppercase;

    @media (max-width: 767px) {
      top: 45vh;
      left: calc(100vw * (calc(50 / var(--width_base))));
      right: calc(100vw * (calc(50 / var(--width_base))));
      font-size: calc(100vw * (calc(20 / var(--width_base))));
    }

    & b,
    & strong {
      font-family: 'Montserrat Medium', sans-serif;
    }
  `;

  const districtMobileCss = css``;

  const districtMobileTitleCss = css`
    font-size: calc(100vw * (calc(20 / var(--width_base))));
    background-color: #93ba1f;
    text-align: center;
    padding: calc(100vw * (calc(15 / var(--width_base))))
      calc(100vw * (calc(20 / var(--width_base))));
    border-bottom-left-radius: calc(100vw * (calc(15 / var(--width_base))));
    border-top-right-radius: calc(100vw * (calc(15 / var(--width_base))));
  `;

  return (
    <div css={sliderItemCss} className="slider-carusel__item">
      <div>
        {data?.imagenFondo && (
          <div css={imageContentCss} className="only-desk slider-image-full">
            <Link
              to={data?.url === '#' ? '/' : data?.url}
              className="img-pointer-none block"
              aria-label={data?.imagenFondo?.title}
              tabIndex="-1"
            >
              <Image
                imagen={data?.imagenFondo}
                classEle="slider-carusel__imagen slider-image-full"
              />
            </Link>
          </div>
        )}
        {data?.imagenFondoMovil && (
          <div
            css={imageContentCss}
            className="only-mov line-0 slider-image-full"
          >
            <Link
              to={data?.url === '#' ? '/' : data?.url}
              className="img-pointer-none block"
            >
              <Image
                imagen={data?.imagenFondoMovil}
                classEle="slider-carusel__imagen slider-image-full"
              />
            </Link>
          </div>
        )}
      </div>
      {data?.estilo === 'predeterminado' && (
        <div css={textCss}>
          {data?.texto && (
            <Text data={data?.texto} styleCss={titleCss} colorText="white" />
          )}
        </div>
      )}

      {data?.estilo === 'estilo2' && (
        <div css={cardCss}>
          {data?.distritoMobile && (
            <div css={districtMobileCss} className="only-mov">
              <Text
                data={data?.distritoMobile}
                styleCss={districtMobileTitleCss}
              />
            </div>
          )}
          <div css={cardContentCss}>
            {data?.logo && (
              <Image
                imagen={data?.logo}
                styleCss={cardImageCss}
                objectFit="contain"
                classEle="only-desk"
              />
            )}
            <span css={cardLineCss} className="only-desk"></span>
            {data?.texto && <Text data={data?.texto} styleCss={cardTextCss} />}
            {data?.distrito && data?.metraje ? (
              <div css={listCss} className="only-desk">
                <div css={listSubCss}>
                  {data?.distrito && (
                    <Text data={data?.distrito} styleCss={listItem2Css} />
                  )}
                  <span css={interlineCss}></span>
                  {data?.metraje && (
                    <Text data={data?.metraje} styleCss={listItem2Css} />
                  )}
                </div>
              </div>
            ) : (
              <div css={listCss} className="only-desk">
                {data?.distrito && (
                  <Text data={data?.distrito} styleCss={listItemCss} />
                )}
                {data?.metraje && (
                  <Text data={data?.metraje} styleCss={listItemCss} />
                )}
              </div>
            )}
            <span css={cardBtn}>VER PROYECTO</span>
          </div>
        </div>
      )}

      {data?.estilo === 'estilo3' && (
        <div css={cardContentStyle3Css}>
          {data?.logo && (
            <Image
              imagen={data?.logo}
              styleContentCss={cardImageStyle3Css}
              objectFit="contain"
            />
          )}

          {data?.texto && (
            <div
              css={cardTextStyle3Css}
              dangerouslySetInnerHTML={{ __html: data?.texto }}
            />
          )}

          {data?.texto2 && (
            <div
              css={cardText2Style3Css}
              dangerouslySetInnerHTML={{ __html: data?.texto2 }}
            />
          )}
        </div>
      )}
    </div>
  );
}
